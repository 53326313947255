import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { PassArrayService } from 'src/app/core/services/pass-array.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CrudService } from 'src/app/core/services/crud.service';
import { apis } from 'src/app/config/apis';

@Component({
  selector: 'app-corporate-forget-id',
  templateUrl: './corporate-forget-id.component.html',
  styleUrls: ['./corporate-forget-id.component.scss']
})
export class CorporateForgetIdComponent implements OnInit {

  forgetIDForm: FormGroup;
  res: any;
  passToken: any;
  mobileNo: any;
  disableButton: boolean = true;
  countryCode: any;
  showCountryCode: any;
  selectedCC: any = 95;

  constructor(private modalService: NgbModal, public dialogRef: MatDialogRef<CorporateForgetIdComponent>, private fb: FormBuilder, private crudService: CrudService,  private _snackbar: MatSnackBar,  public dialog: MatDialog, private token: PassArrayService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.forgetIDForm = this.fb.group({
      // name: ['', Validators.required],
      mobile: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(13),
        Validators.pattern('[0-9]+')
      ]],
      email: ["", Validators.compose([ Validators.required,Validators.email, Validators.pattern(/\S+@\S+\.\S+/),]),],
      id_country_code: ['+91'],
      // gst:['', [
      //   Validators.required,
      //   Validators.minLength(15),
      //   Validators.maxLength(15),
      // ]],
    });

    this.forgetIDForm.valueChanges.subscribe( value => {
      this.mobileNo = value;
      if (this.mobileNo.mobile.length > 6 && this.mobileNo.mobile.length < 14 && !this.forgetIDForm.invalid) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    }
      )
    this.token.currentGst.subscribe(gst => this.passToken = gst );
    // this.getCountryCode();

    // localStorage.setItem('countryCode', this.selectedCC);
  }

  // getCountryCode() {
  //   this.crudService.get(apis.COUNTRY_CODES).subscribe(
  //     data => {
  //       this.countryCode = data;
  //       this.showCountryCode = this.countryCode.codes;
  //     });
  // }
  // openVerticallyCentered(content) {
  //   this.modalService.open(content, { centered: true });
  // }

  onNoClick(data): void {
    this.dialogRef.close(data);
  }

  // selectedCountryCode() {
  //   this.selectedCC = this.forgetIDForm.value.id_country_code;
  //   this.selectedCC =  this.selectedCC.id_country_code;
  //   localStorage.setItem('countryCode', this.selectedCC);
  // }

  forget_corporate_id() {
    this.spinner.show();
    let obj = {
      email_id: this.forgetIDForm.get('email').value.toLowerCase(),
      mobile_number: this.forgetIDForm.get('mobile').value,
      "airline_id": 42,
      "user_role_id":19,
    }

    this.crudService.postFormdata(apis.FORGET_CORPORATE_ID, obj).subscribe(
      response => {
        this.res = response;
        this.spinner.hide();
        if (this.res.status) {
          this._snackbar.open(this.res.message, 'X', {duration: 3000, verticalPosition: 'top'
          });
          this.onNoClick('loginFromRegister');
        } else {
          this._snackbar.open(this.res.message, 'X', {duration: 4000, verticalPosition: 'top'
          });
        }
      },
      err => {
        this.spinner.hide(); 
      });
  }

  // validateName(event) {
  //   if ((event.keyCode < 65 || event.keyCode > 122) && event.key != " " || event.key == "^" || event.key == "_") {
  //     event.preventDefault();
  //   }
  // }

  // // name validation
  // validateNameSpace() {
  //   if (this.forgetIDForm.controls["name"].value.startsWith(" ")) {
  //     this.forgetIDForm.controls["name"].setValue("");
  //   }
  // }

  // allowOnly_num_alpha(e) {  
  //   // Accept only alpha numerics, not special characters 
  //   var regex = new RegExp("^[a-zA-Z0-9 ]+$");
  //   var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  //   if (regex.test(str)) {
  //       return true;
  //   }

  //   e.preventDefault();
  //   return false;
  // }

  // onPaste(e) {
  //   e.preventDefault();
  //   return false;
  // }

}
