import { CrudService } from "src/app/core/services/crud.service";
import { Component } from "@angular/core";
import { PassArrayService } from "./core/services/pass-array.service";
// import { apis } from "./config/apis";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  loading: boolean = false;

  constructor(public getLoader: PassArrayService, public crud: CrudService) {}
  title = "CarterX";

  ngOnInit() {
    this.getLoader.currentLoaderFlag.subscribe(
      (loader) => (this.loading = loader)
    );
    // if(localStorage.loginUserDetails){
    //   this.accessTokenApi();
    // }
  }

  // accessTokenApi() {
  //   let login_details = JSON.parse(localStorage.getItem('loginUserDetails'))
  //   let reqBody = {
  //     client_id: login_details.customer_detail.client_id,
  //     client_secret: login_details.customer_detail.client_secret,
  //     grant_type: "client_credentials",
  //   };

  //   this.crud
  //     .getToken(apis.GET_LOGIN_TOKEN, reqBody)
  //     .subscribe((response: any) => {
  //       if (response) {
  //         const key = "accessToken";
  //         localStorage.setItem(key, response.access_token);
  //         this.getLoader.passToken(response.access_token);
  //         localStorage.setItem("carterXAccessToken", response.access_token);
  //       }
  //     });
  // }
}
