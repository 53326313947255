import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { apis } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";

@Component({
  selector: "app-priority-customer-list",
  templateUrl: "./priority-customer-list.component.html",
  styleUrls: [
    "./priority-customer-list.component.scss",
    "../subscription-confirmation/subscription-confirmation.component.scss",
    "../order-confirm/order-confirm.component.scss",
  ],
})
export class PriorityCustomerListComponent implements OnInit {
  customer_list: any = [];

  constructor(public crud: CrudService, public spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.get_customer_list();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  get_customer_list() {
    this.spinner.show();
    let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    const reqBody = {
      customer_id: obj.customer_detail.id_customer,
    };
    this.crud
      .postLogin(apis.PRIORITY_CUSTOMER_LIST, reqBody)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.customer_list = res.result;
        } else {
        }
      });
  }
}
