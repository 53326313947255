import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { apis } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";

@Component({
  selector: "app-subscription-list",
  templateUrl: "./subscription-list.component.html",
  styleUrls: [
    "./subscription-list.component.scss",
    "../subscription-confirmation/subscription-confirmation.component.scss",
    "../order-confirm/order-confirm.component.scss",
  ],
})
export class SubscriptionListComponent implements OnInit {
  subscription_list: any = [];
  count: any = 1;
  no_data_found:any = false;
  total_page_count: any = 0;

  constructor(public crud: CrudService, public spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.get_subscription_list();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  previous() {
    if (this.count > 0) {
      this.count--;
      this.get_subscription_list();
    }
  }

  next() {
    this.count++;
    this.get_subscription_list();
  }

  get_subscription_list() {
    this.spinner.show();
    let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    const reqBody = {
      email: obj.customer_detail.email,
      mobile: obj.customer_detail.mobile,
      page_no: this.count,
    };
    this.crud
      .postLogin(apis.SUBSCRIPTION_LIST, reqBody)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.status) {
          res.subscriber_detail.map((res1: any) => {
            res1.status = res1.status.replace("_", " ");
          });
          this.subscription_list = res.subscriber_detail;
          this.total_page_count = Math.ceil(Number(res["total_page"]));
          this.subscription_list == 0 ? this.no_data_found = true : this.no_data_found = false
        } else {
          this.no_data_found = true;
        }
      },(err) => {
        this.no_data_found = true
        this.spinner.hide();
      });
  }
}
