import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargo-transfer-terms-and-condition',
  templateUrl: './cargo-transfer-terms-and-condition.component.html',
  styleUrls: ['./cargo-transfer-terms-and-condition.component.scss']
})
export class CargoTransferTermsAndConditionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
