import { Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { PassArrayService } from 'src/app/core/services/pass-array.service';

@Component({
  selector: 'app-corporate-order',
  templateUrl: './corporate-order.component.html',
  styleUrls: ['./corporate-order.component.scss']
})
export class CorporateOrderComponent implements OnInit {
  login_user:any
  carterXtype: any = 1;
  scrollDown: boolean = true;

  localStorage_remove_items = ['localOutstationFlag','userInput','toFromFlag','luggageData','contactDetails','pickupContactDetails',
    'keepArrayOfluggae','keepweightOfLuggage','selectedDate','saveObj','noOfBags','orderSub','outstationResponse','outStationArray','deliveryDate',
    'region_id','showDate','timeOnwards','insurancePrice','totalPrice','keepDate','dateRefresh','pickupCC','deliveryCC','ccToSend','orderSubNew',
    'showDateSecondPage','pickupFromLocal','deliveryFromLocal','keepDateFrom','fromDate','nextDate']

  constructor(public passArray: PassArrayService,public router:Router) { 
    let loginDetails = JSON.parse(localStorage.corporateUserDetails ? localStorage.corporateUserDetails : null)
    if(loginDetails && loginDetails.customer_detail){
      loginDetails.customer_detail.name ? this.login_user = true : null
    }

    if(this.router.url == '/home'){
      if(this.login_user){
        this.router.navigateByUrl('/corporate');
        this.passArray.corporate_user_detail.next('login');
      }
    }else if(this.router.url == '/corporate'){
      if(!this.login_user){
        this.router.navigateByUrl('/home');
      }
    }
  }
  

  ngOnInit() {
    window.scroll(0, 0);
    this.localStorage_remove_items.map((res)=>{localStorage.res ? localStorage.removeItem(res) : null})
    this.passArray.carterxtype.subscribe(carterxType => this.carterXtype = carterxType);
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 200) {
      this.scrollDown = false;
    }
    else {
      this.scrollDown = true;
    }
  }

}
