import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoginComponent } from 'src/app/shared/modals/login/login.component';
import { CorporateLoginComponent } from '../corporate-sign-in/corporate-login/corporate-login.component';

@Component({
  providers: [CustomerCorporateLoginComponent],
  selector: 'app-customer-corporate-login',
  templateUrl: './customer-corporate-login.component.html',
  styleUrls: ['./customer-corporate-login.component.scss']
})
export class CustomerCorporateLoginComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomerCorporateLoginComponent>,
  ) { }

  corporateTab:any = false
  customerTab: any = true
  
  ngOnInit() { }

  onTabClick(type) {
    if (type == "corporate") {
      this.corporateTab = true
      this.customerTab = false
    } else {
      this.corporateTab = false
      this.customerTab = true
    }
  }
  
  //   signin() {
  //   this.openDialog();
  //   }
  //   openDialog(): void {
  //    const dialogRef = this.dialog.open(LoginComponent, {
  //     width: "320px",
  //     // data: {name: this.name, animal: this.animal}
  //    });

  //    dialogRef.afterClosed().subscribe((result) => {
  //     console.log("The dialog was closed");
  //     // this.animal = result;
  //    });
  //   }
  //   signin_corporate(): void {
  //     const dialogRef = this.dialog.open(CorporateLoginComponent, {
  //       width: "320px",
        
  //     });

  //     dialogRef.afterClosed().subscribe((result) => {
  //       console.log("The dialog was closed");
  //     });
  //   }
  //   onNoClick(): void {
  //   this.dialogRef.close();
  //  }

}
