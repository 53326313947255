import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AddCardComponent } from "../add-card/add-card.component";

@Component({
  selector: "app-listing-cards",
  templateUrl: "./listing-cards.component.html",
  styleUrls: ["./listing-cards.component.scss"],
})
export class ListingCardsComponent implements OnInit {
  @Input() cardsList: any;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    // this.cardsList = cardsList
  }

  public openCard(item): void {
    console.log(item, "namename");
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: "390px",
      // height: "599px",
      panelClass: "addCardMob",
      data: {
        selectedItem: item,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
