import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrival-details',
  templateUrl: './arrival-details.component.html',
  styleUrls: ['./arrival-details.component.scss']
})
export class ArrivalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
