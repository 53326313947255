import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargo-cancellation-and-refund',
  templateUrl: './cargo-cancellation-and-refund.component.html',
  styleUrls: ['./cargo-cancellation-and-refund.component.scss']
})
export class CargoCancellationAndRefundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
