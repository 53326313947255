import { CorporateForgetIdComponent } from './../corporate-forget-id/corporate-forget-id.component';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatSnackBar } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { CrudService } from 'src/app/core/services/crud.service';
import { PassFlagService } from 'src/app/core/services/passFlagService/pass-flag.service';
import { CorporateOtpVerifyComponent } from './../corporate-otp-verify/corporate-otp-verify.component';
import { CorporateRegisterComponent } from './../corporate-register/corporate-register.component';
import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { apis } from "src/app/config/apis";

@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
  styleUrls: ['./corporate-login.component.scss']
})
export class CorporateLoginComponent implements OnInit {

  @Input() type = "text";
  loginForm: FormGroup;
  countryCode: any;
  passLoginData: any;
  disableButton: boolean = true;
  mobileNo: any;
  checkNo: any = [];
  loginRes: any;
  selectedCC: any = 95;
  showCountryCode: any;

  searchText;
  selectedCountry;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<CorporateLoginComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private crudService: CrudService,
    private passData: PassFlagService,
    private _snackbar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      corporate_id: [
        "",
        [
          Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10),
          // Validators.pattern("[0-9]+")
        ]
      ],
      id_country_code: ["+91"]
    });
    this.loginForm.valueChanges.subscribe(value => {
      this.mobileNo = value;
      // console.log(this.mobileNo);
      if (this.loginForm.valid) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    });

    this.getCountryCode();
    localStorage.setItem("countryCode", this.selectedCC);
  }

  getCountryCode() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe(data => {
      this.countryCode = data;
      this.showCountryCode = this.countryCode.codes;
    });
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  register() {
    this.openDialog();
  }

  openDialogOtp(): void {
    const dialogRef = this.dialog.open(CorporateOtpVerifyComponent, {
      width: "300px"
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case "Back": {
          break;
        }

        case "Login": {
          this.onNoClick();
          break;
        }

        default: {
          break;
        }
      }
      console.log("vefiyotp closed");
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CorporateRegisterComponent, {
      width: "300px",
      panelClass : "register-modal"
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case "loginFromRegister": {
          break;
        }
        case "closeRegister": {
          break;
        }
        case "privacyTerms": {
          this.onNoClick();
          break;
        }
      }
    });
  }

  selectedCountryCode() {
    this.selectedCC = this.loginForm.value.id_country_code;
    this.selectedCC = this.selectedCC.id_country_code;
    localStorage.setItem("countryCode", this.selectedCC);
  }

  login() {
    this.spinner.show();

    let obj = {
      corporate_id: this.loginForm.value.corporate_id.toUpperCase(),
      id_country_code: this.selectedCC,
      airline_name: 'CarterX',
    };

    localStorage.setItem("userLoginNumberCorporate", JSON.stringify(obj));
    this.crudService.postLogin(apis.USER_LOGIN, obj).subscribe(response => {
      this.passLoginData = this.loginForm.value;
      this.passLoginData.mobile = response['mobile'];
      this.passLoginData.id_customer = response['id_customer'];
      this.passData.setOption(this.passLoginData);
      this.loginRes = response;
      this.spinner.hide();
      if (this.loginRes.status) {
        this.openDialogOtp();
      } else {
        this._snackbar.open(this.loginRes.message, "X", {
          duration: 4000,
          verticalPosition: "top"
        });
      }
    },err=>{this.spinner.hide();});
  }

  forget_id() {
    const dialogRef = this.dialog.open(CorporateForgetIdComponent, {
      width: "300px"
      // data: {name: this.name, animal: this.animal}
    });
    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case "Back": {
          break;
        }

        case "Login": {
          this.onNoClick();
          break;
        }

        default: {
          break;
        }
      }
    });
  }
}
