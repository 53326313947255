import { PassArrayService } from "src/app/core/services/pass-array.service";
import { Router } from "@angular/router";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatSnackBar,
  MatStepper,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { apis, RZP_PAYMENT_APIS, subscription } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";
import { UtilService } from "src/app/core/services/util.service";
import { environment } from "src/environments/environment";
import sjcl from "sjcl";
declare var Razorpay: any;
declare var Card: any;

@Component({
  selector: "app-add-card",
  templateUrl: "./add-card.component.html",
  styleUrls: ["./add-card.component.scss"],
})
// @Directive({
//   selector: "[credit-card]",
// })
export class AddCardComponent implements OnInit {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  cardForm: FormGroup;
  user_info: FormGroup;
  issuerGroup: FormGroup;
  disableButton: boolean = true;
  bankList: any = ["ICICI Bank", "HDFC Bank"];
  razorpay: any;
  razorpay_payment_id: any;
  submitted: any;
  countryCode: any;
  country_code_id: any = 95;
  showCountryCode: any;
  user_details_disable: any = false;
  is_login: boolean = false;
  expire_date_valid: any = false;
  is_otp_verify: any = false;
  res_code: any;
  cardList: any = [];
  categoryList: any;
  isLinear = true;
  customerIdInput: boolean = false;
  issuer_step: boolean = false;
  constructor(
    private razorPays: UtilService,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<AddCardComponent>,
    private _snackbar: MatSnackBar,
    private fb: FormBuilder,
    public crudService: CrudService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public passArray: PassArrayService,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedItem: any }
  ) {
    this.razorPays
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
    this.createForm();
  }

  ngOnInit() {
    this.getCountryCode();
    this.createForm();

    // this.enableBtn();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      new Card({
        form: "form",
        container: ".card",
        formSelectors: {
          numberInput: "input[name=number]",
          expiryInput: "input[name=expiry]",
          cvcInput: "input[name=cvv]",
          // nameInput: "input[name=name]",
        },

        width: 390, // optional — default 350px
        formatting: true,

        placeholders: {
          number: "XXXX XXXX XXXX XXXX",
          bank: "Bank Name",
          expiry: "XX/XX",
          cvc: "XXX",
        },
      });
    }, 0);
  }

  createForm() {
    // card details form
    console.log("asdasdasdasd", this.data);
    this.cardForm = this.fb.group({
      card_number: ["", Validators.required],
      customer_id: [
        "",
        this.data.selectedItem.mapped_with == "customer_id" ||
        this.data.selectedItem.mapped_with == "both"
          ? Validators.required
          : "",
      ],
      // bank_name: ["", Validators.required],
      expiry: ["", Validators.required],
      cvv: ["", [Validators.required, Validators.minLength(3)]],
      card_holder_name: ["", Validators.required],
    });

    // issuer details form
    this.issuerGroup = this.fb.group({
      issuer_list: ["", Validators.required],
      category_list: ["", Validators.required],
    });

    // user information form
    this.user_info = this.fb.group({
      user_name: ["", Validators.required],
      country: ["91", Validators.required],
      mobile_number: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(/\S+@\S+\.\S+/),
        ]),
      ],
      otp: [""],
    });

    this.setLoginDetails();
    this.getCardList();
  }

  // enableBtn(){
  //   this.cardForm.valueChanges.subscribe((value) => {
  //     if (!this.cardForm.invalid) {
  //       this.disableButton = false;
  //     } else {
  //       this.disableButton = true;
  //     }
  //   });
  // }

  getCountryCode() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe((data) => {
      this.countryCode = data;
      this.showCountryCode = this.countryCode.codes;
      // "id_country_code"
    });
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && event.key != " ") {
      return false;
    }
    return true;
  }

  issuer_category: any = {};
  issuer_details: any = [];
  handleDropdownEvent(formControllName, value) {
    switch (formControllName) {
      // case "bank_name":
      //   this.cardForm.controls["bank_name"].setValue(value);
      //   break;
      case "issuer_list":
        console.log(value);
        this.issuerGroup.controls["issuer_list"].setValue(value.issuer_name);
        this.categoryList = value.category_info;
        this.issuerGroup.controls["category_list"].setValue("");
        this.issuer_details = value;
        break;
      case "country":
        this.user_info.controls["country"].setValue(value.country_code);
        this.country_code_id = value.id_country_code;
        break;
      case "category_list":
        this.issuerGroup.controls["category_list"].setValue(
          value.issuer_category
        );
        this.issuer_category = value;
        if (value.mapped_with == "customer_id" || value.mapped_with == "both") {
          this.cardForm.controls["customer_id"].setValidators(
            Validators.required
          );
          this.cardForm.controls["customer_id"].updateValueAndValidity();
          console.log(this.cardForm, "form1");
        } else {
          this.cardForm.controls["customer_id"].clearValidators();
          this.cardForm.controls["customer_id"].updateValueAndValidity();
          console.log(this.cardForm, "form2");
        }

        if (value.mapped_with == "customer_id" || value.mapped_with == "both") {
          this.cardForm.controls["customer_id"].setValidators(
            Validators.required
          );
          this.cardForm.controls["customer_id"].updateValueAndValidity();
          this.customerIdInput = true;
        } else {
          this.customerIdInput = false;
          this.cardForm.controls["customer_id"].clearValidators();
          this.cardForm.controls["customer_id"].updateValueAndValidity();
        }
        break;
    }
  }

  error() {
    console.log(this.cardForm, "form3");
  }

  payLoadRequest(type) {
    let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    let cardNum: any = document
      .getElementById("cardNumber")
      ["value"].replace(/\s/g, "");
    let firstSixDigits: any = this.cardForm
      .get("card_number")
      .value.replace(/\s/g, "")
      .substring(0, 6);
    let lastFourDigits: any = cardNum.slice(-4);
    let myBitArray: any = sjcl.hash.sha256.hash(
      firstSixDigits + lastFourDigits
    );
    let myHash: any = sjcl.codec.hex.fromBits(myBitArray);

    var reqBody: any = {
      hashcode: myHash,
      bin_customer_id:
        this.issuer_category.mapped_with == "customer_id" ||
        this.issuer_category.mapped_with == "both"
          ? this.cardForm.get("customer_id").value
          : "",
      category_id: this.issuer_category.category_id,
      issuer_id: this.issuer_details.id,
      // bank_name: "",
    };

    if (type == 1) {
      reqBody = {
        ...reqBody,
        ...{
          bank_name: "",
          paymentId: this.razorpay_payment_id,
          last4Digit: lastFourDigits,
          customerId: obj.customer_detail.id_customer
            ? obj.customer_detail.id_customer
            : "",
          customer_bin_no: this.cardForm
            .get("card_number")
            .value.replace(/\s/g, "")
            .substring(0, 6),
          customer_name: this.user_info.get("user_name").value,
          customer_email: this.user_info.get("email").value,
          customer_mobile: this.user_info.get("mobile_number").value,
          country_code_id: this.country_code_id,
          mapped_with: this.issuer_category.mapped_with,
          card_exp_date: this.cardForm.get("expiry").value,
        },
      };
    } else {
      reqBody = {
        ...reqBody,
        ...{
          card_bin_no: this.cardForm
            .get("card_number")
            .value.replace(/\s/g, "")
            .substring(0, 6),
          type: this.issuer_category.mapped_with,
          // customer_id: obj.customer_detail.id_customer ? obj.customer_detail.id_customer : "",
        },
      };
    }

    return reqBody;
  }

  verifyBinNumber() {
    this.spinner.show();
    // const cardNum = document.getElementById("cardNumber")["value"].replace(/\s/g, "");
    // const firstSixDigits = this.cardForm.get("card_number").value.replace(/\s/g, "").substring(0, 6);
    // const lastFourDigits = cardNum.slice(-4);
    // const myBitArray = sjcl.hash.sha256.hash(firstSixDigits + lastFourDigits);
    // const myHash = sjcl.codec.hex.fromBits(myBitArray);
    // let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    // let reqBody = {
    //   card_bin_no: this.cardForm.get("card_number").value.replace(/\s/g, "").substring(0, 6),
    //   customer_id: obj.customer_detail.id_customer ? obj.customer_detail.id_customer : "",
    //   bank_name: this.cardForm.get("bank_name").value,
    //   hashcode: myHash,
    // };
    this.crudService
      .post(apis.BIN_NUMBER, this.payLoadRequest(2))
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.proceedToPay();
        } else {
          this.printToastMsg(res.message);
        }
      });
  }

  check_card_hash() {
    this.spinner.show();
    // let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    // const cardNum = document.getElementById("cardNumber")["value"].replace(/\s/g, "");
    // const firstSixDigits = this.cardForm.get("card_number").value.replace(/\s/g, "").substring(0, 6);
    // const lastFourDigits = cardNum.slice(-4);
    // const myBitArray = sjcl.hash.sha256.hash(firstSixDigits + lastFourDigits);
    // const myHash = sjcl.codec.hex.fromBits(myBitArray);

    // const reqBody = {
    //   hashcode: myHash,
    //   paymentId: this.razorpay_payment_id,
    //   last4Digit: lastFourDigits,
    //   customerId: obj.customer_detail.id_customer ? obj.customer_detail.id_customer : "",
    //   bank_name: this.cardForm.get("bank_name").value,
    //   customer_bin_no: this.cardForm.get("card_number").value.replace(/\s/g, "").substring(0, 6),
    // };

    this.crudService
      .post(apis.CHECK_CARD_HASH, this.payLoadRequest(1))
      .subscribe((res: any) => {
        this.dialogRef.close();
        this.spinner.hide();
        if (res.status_code == 200 || res.status_code == 203) {
          this.printToastMsg(res.message);
          this.passArray.getPriorityCustomer.next("Success");
          localStorage.setItem(
            "priority_customer_info",
            JSON.stringify(res.result)
          );
          this.router.navigateByUrl("/confirmation-bin-id");
          localStorage.setItem(
            "check_card_hash",
            JSON.stringify(res.result.subscription_info)
          );
        } else {
          this.printToastMsg(res.message);
        }
      });
  }

  verify_user() {
    this.spinner.show();

    let reqBody = {
      customer_email: this.user_info.get("email").value,
      customer_mobile: this.user_info.get("mobile_number").value,
      customer_name: this.user_info.get("user_name").value,
      country_code_id: this.user_info.controls["country"].value,
    };

    this.crudService.post(apis.BEFORE_LOGIN, reqBody).subscribe(
      (res: any) => {
        this.res_code = res.response.res_status;
        if (res.response.res_status == 201) {
          this.is_otp_verify = true;
        } else if (res.response.res_status == 200) {
          // this.login_usr_details(res.response.token);
          this.is_otp_verify = true;
        }
        this.spinner.hide();
        res.response.res_status == 200
          ? null
          : this.printToastMsg(res.response.msg);
        console.log(res);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  otp_verify() {
    if (
      this.user_info.status == "VALID" &&
      this.user_info.controls["otp"].value != "" &&
      this.user_info.controls["otp"].value.length == 4
    ) {
      this.spinner.show();
      let formValue = { ...this.user_info.value };
      const reqBody = {
        email: formValue.email.toLowerCase(),
        mobile: formValue.mobile_number,
        name: formValue.user_name,
        country_code: this.user_info.controls["country"].value,
        type: true,
        res_status: this.res_code,
      };
      reqBody["otp"] = this.user_info.controls["otp"].value;
      this.crudService.post(subscription.VERIFY_USER_NUMBER, reqBody).subscribe(
        (res: any) => {
          // this.spinner.hide();
          if (res.result.msg == "OTP not match") {
            this.printToastMsg(res.result.msg);
            this.spinner.hide();
          } else {
            this.login_usr_details(res.result.token);
            // this.printToastMsg(res.result.msg);
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else {
      this.printToastMsg(
        this.user_info.status == "VALID"
          ? "Please Enter OTP"
          : "Please Fill The All Fields"
      );
    }
  }

  login_usr_details(response: any) {
    let loginDetails = {
      status: true,
      message: "Number Verified Successfully",
      customer_detail: {
        id_customer: response[0].id_customer,
        name: response[0].name,
        email: response[0].email.toLowerCase(),
        mobile: response[0].mobile,
        fk_tbl_customer_id_country_code:
          response[0].fk_tbl_customer_id_country_code,
        id_country_code: response[0].fk_tbl_customer_id_country_code,
        mobile_number_verification: "1",
        client_id: response[0].client_id,
        client_secret: response[0].client_secret,
      },
      saved_address: {
        registered_address: {},
        last_order_address: false,
      },
    };

    localStorage.setItem("loginUserDetails", JSON.stringify(loginDetails));
    this.passArray.newEventFordata("LoggedIn!");
    this.passArray.passUserName(response[0].name);

    this.accessTokenApi({
      client_id: response[0].client_id,
      client_secret: response[0].client_secret,
      grant_type: "client_credentials",
    });
    setTimeout(() => {
      this.stepper.selectedIndex = 0;
    }, 6000);
  }

  accessTokenApi(obj) {
    this.crudService
      .getToken(apis.GET_LOGIN_TOKEN, obj)
      .subscribe((response: any) => {
        if (response) {
          localStorage.setItem("accessToken", response.access_token);
          this.passArray.passToken(response.access_token);
          localStorage.setItem("carterXAccessToken", response.access_token);
          this.setLoginDetails();
        }
      });
    this.passArray.newEventFordata("LoggedIn!");
  }

  proceedToPay() {
    const cardNum = document.getElementById("cardNumber")["value"];
    let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
    this.submitted = true;
    let options = {
      key: environment.razorPayKey,
      amount: Number("200"),
      currency: "INR",
      name: "CarterPorter",
      description: "Payment towards Carter",
      image: "https://cdn.razorpay.com/logos/Du4P7LfElD9azm_medium.jpg",
      // readonly: { name: true, "card[number]": true },

      handler: (response) => {
        this.razorpay_payment_id = response.razorpay_payment_id;
        this.ngZone.run(() => {
          this.check_card_hash();
          // this.refund_payment();
        });
      },
      prefill: {
        name: this.cardForm.get("card_holder_name").value,
        email: this.user_info.get("email").value.toLowerCase(),
        contact: this.user_info.get("mobile_number").value,
        "card[number]": cardNum,
        "card[expiry]": this.cardForm.get("expiry").value,
        "card[cvv]": this.cardForm.get("cvv").value,
      },
      notes: {
        address: "note value",
      },
      theme: {
        color: "#F37254",
      },
      method: {
        card: true,
        netbanking: false,
        wallet: false,
        upi: false,
      },
      config: {
        display: {
          hide: [{ method: "paylater" }],
        },
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  }

  printToastMsg(msg) {
    this._snackbar.open(msg, "X", {
      duration: 10000,
      verticalPosition: "top",
      panelClass: "custom-snackbar",
    });
  }

  expiry_date_validation() {
    const month_year = this.cardForm.get("expiry").value.split("/");
    const user_enter_month = month_year[0];
    const user_enter_year = month_year[1];

    const current_Month = new Date().getMonth() + 1;
    var current_Year = new Date().getFullYear().toString();
    current_Year = current_Year.slice(-2);

    if (Number(user_enter_year) > Number(current_Year)) {
      this.expire_date_valid = false;
      if (this.is_login) {
        this.verifyBinNumber();
      } else {
        this.verify_user();
      }
    } else if (Number(user_enter_year) == Number(current_Year)) {
      if (Number(user_enter_month) >= Number(current_Month)) {
        this.expire_date_valid = false;
        if (this.is_login) {
          this.verifyBinNumber();
        } else {
          this.verify_user();
        }
      } else {
        this.expire_date_valid = true;
        // this.printToastMsg("Entered Date is not valid");
      }
    } else {
      this.expire_date_valid = true;
      // this.printToastMsg("Entered Date is not valid");
    }
  }

  setLoginDetails() {
    if (localStorage.loginUserDetails) {
      let obj = JSON.parse(localStorage.getItem("loginUserDetails"));
      this.user_info.controls["user_name"].setValue(obj.customer_detail.name);
      this.user_info.controls["mobile_number"].setValue(
        obj.customer_detail.mobile
      );
      this.user_info.controls["email"].setValue(obj.customer_detail.email);
      this.user_info.controls["country"].setValue(
        obj.customer_detail.fk_tbl_customer_id_country_code
      );
      if (this.showCountryCode) {
        let conty_code: any = this.showCountryCode.find(
          (res) =>
            Number(res.country_code) ==
            Number(obj.customer_detail.fk_tbl_customer_id_country_code)
        );
        this.country_code_id = conty_code.id_country_code;
      }
      this.is_otp_verify = false;
      this.user_details_disable = this.is_login = true;
    }
  }

  issuer_image_url: any;
  getCardList() {
    this.spinner.show();
    this.crudService.get(apis.ISSUER_CATEGORY_LIST).subscribe((res: any) => {
      this.spinner.hide();
      if (res.result) {
        this.cardList = res.result;
        this.categoryList = this.data.selectedItem.category_info;
        this.issuerGroup.controls["issuer_list"].setValue(
          this.data.selectedItem.issuer_name
        );

        this.issuer_details = this.data.selectedItem;
        this.issuer_category = this.data.selectedItem.category_info[0];
        this.issuer_image_url =
          res.image_url + this.data.selectedItem.issuer_image;

        this.issuerGroup.controls["category_list"].setValue(
          this.data.selectedItem.category_info[0].issuer_category
        );

        if (
          this.data.selectedItem.category_info[0].mapped_with ==
            "customer_id" ||
          this.data.selectedItem.category_info[0].mapped_with == "both"
        ) {
          this.cardForm.controls["customer_id"].setValidators(
            Validators.required
          );
          this.cardForm.controls["customer_id"].updateValueAndValidity();
          this.customerIdInput = true;
        } else {
          this.customerIdInput = false;
          this.cardForm.controls["customer_id"].clearValidators();
          this.cardForm.controls["customer_id"].updateValueAndValidity();
        }

        console.log(this.cardForm, "form");
      } else {
        this.cardList = false;
      }
    });
  }
  issuerStep() {
    this.issuer_step = true;
  }
}
