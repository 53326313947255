import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CrudService } from "src/app/core/services/crud.service";

@Component({
  selector: "app-subscription-confirmation",
  templateUrl: "./subscription-confirmation.component.html",
  styleUrls: [
    "./subscription-confirmation.component.scss",
    "../order-confirm/order-confirm.component.scss",
  ],
})
export class SubscriptionConfirmationComponent implements OnInit {
  subcription: any = [];
  confirmation_bin_id: any;

  constructor(public router: Router) {}

  ngOnInit() {
    console.log(this.router.url);
    if (this.router.url == "/subscription-confirmation") {
      this.subcription = localStorage.subscription_details
        ? JSON.parse(localStorage.getItem("subscription_details"))
        : [];
    } else {
      let res = JSON.parse(
        localStorage.priority_customer_info
          ? localStorage.priority_customer_info
          : null
      );

      this.subcription = localStorage.check_card_hash
        ? JSON.parse(localStorage.getItem("check_card_hash"))
        : [];

      this.confirmation_bin_id = res.unique_id
        ? res.unique_id
        : res.customer_uniqe_pid;
      // ? res.customer_uniqe_pid
      // : "";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
}
