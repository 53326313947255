import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { PassArrayService } from 'src/app/core/services/pass-array.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CrudService } from 'src/app/core/services/crud.service';
import { apis } from 'src/app/config/apis';

@Component({
  selector: 'app-corporate-register',
  templateUrl: './corporate-register.component.html',
  styleUrls: ['./corporate-register.component.scss']
})
export class CorporateRegisterComponent implements OnInit {

  registerForm: FormGroup;
  res: any;
  passToken: any;
  mobileNo: any;
  disableButton: boolean = true;
  countryCode: any;
  showCountryCode: any;
  selectedCC: any = 95;

  constructor(private modalService: NgbModal, public dialogRef: MatDialogRef<CorporateRegisterComponent>, private fb: FormBuilder, private crudService: CrudService,  private _snackbar: MatSnackBar,  public dialog: MatDialog, private token: PassArrayService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      mobile: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(13),
        Validators.pattern('[0-9]+')
      ]],
      email: ['', Validators.compose([ Validators.required,Validators.email, Validators.pattern(/\S+@\S+\.\S+/),]),],
      id_country_code: ['+91'],
      gst:['', [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
      ]],
    });

    this.registerForm.valueChanges.subscribe( value => {
      this.mobileNo = value;
      if (this.mobileNo.mobile.length > 6 && this.mobileNo.mobile.length < 14 && !this.registerForm.invalid) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    }
      )
    this.token.currentGst.subscribe(gst => this.passToken = gst );
    this.getCountryCode();

    localStorage.setItem('countryCode', this.selectedCC);
  }

  getCountryCode() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe(
      data => {
        this.countryCode = data;
        this.showCountryCode = this.countryCode.codes;
      });
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  onNoClick(data): void {
    this.dialogRef.close(data);
  }

  selectedCountryCode() {
    this.selectedCC = this.registerForm.value.id_country_code;
    this.selectedCC =  this.selectedCC.id_country_code;
    localStorage.setItem('countryCode', this.selectedCC);
  }

  register() {
    this.spinner.show();
    let obj = {
      name: this.registerForm.get('name').value,
      email: this.registerForm.get('email').value.toLowerCase(),
      mobile: this.registerForm.get('mobile').value,
      gst_number:(this.registerForm.get('gst').value).toUpperCase(),
      airline_id:42,
      // other_comments:"",
      // date_of_birth:"",
      // address_line_1:"",
      // address_line_2:"",
      // area:"",
      // pincode:"",
      // gender: 0,
      country_code: this.selectedCC ? this.selectedCC : '95',
      // landmark:"",
      // building_number:"",
      // building_restriction:"",
      // customer_document: ""
    }

    this.crudService.postFormdata(apis.CORPORATE_USER_REGISTER, obj).subscribe(
      response => {
        this.res = response;
        this.spinner.hide();
        if (this.res.status) {
          this._snackbar.open(this.res.message, 'X', {duration: 3000, verticalPosition: 'top'
          });
          this.onNoClick('loginFromRegister');
        } else {
          this._snackbar.open(this.res.message, 'X', {duration: 4000, verticalPosition: 'top'
          });
        }
      },
      err => {
        this.spinner.hide(); 
      });
  }

  validateName(event) {
    if ((event.keyCode < 65 || event.keyCode > 122) && event.key != " " || event.key == "^" || event.key == "_") {
      event.preventDefault();
    }
  }

  // name validation
  validateNameSpace() {
    if (this.registerForm.controls["name"].value.startsWith(" ")) {
      this.registerForm.controls["name"].setValue("");
    }
  }

  allowOnly_num_alpha(e) {  
    // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

}
