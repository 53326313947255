import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmMap } from '@agm/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-track-live-location',
  templateUrl: './track-live-location.component.html',
  styleUrls: ['./track-live-location.component.scss']
})
export class TrackLiveLocationComponent implements OnInit {

  latitude: any;
  longitude: any;
  zoom: any = 20;
  last_update_time: any

  constructor(
    public dialogRef: MatDialogRef<TrackLiveLocationComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { current_location },
  ) {

    if (this.data && this.data.current_location) {
      this.latitude = Number(this.data.current_location.latitude)
      this.longitude = Number(this.data.current_location.longitude)
      this.last_update_time = this.data.current_location.modified_date
    }

  }

  ngOnInit() {
  }


}
