import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safty-first',
  templateUrl: './safty-first.component.html',
  styleUrls: ['./safty-first.component.scss']
})
export class SaftyFirstComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
